import React from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <h1>404</h1>
        <p>Whatever you were looking for ain&#39;t here.</p>
        <Link to="/">← Go Back to the Homepage</Link>
      </Layout>
    )
  }
}

export default NotFoundPage
